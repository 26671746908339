.container.full {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-left: 0;
  margin-bottom: 0;
  border: 1px solid var(--mid-shadow);
  border-radius: var(--border-radius-md);
  pointer-events: all;

  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 340px;
  }

  @media (min-width: 769px) {
    height: 250px;
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    height: 280px;
  }

  @media (max-width: 992px) and (min-width: 768px) {
    height: 250px;
  }

  @media screen and (min--moz-device-pixel-ratio: 0) and (max-width: 768px) {
    border-left-width: 1.2px;
  }

  &.loadingSkeleton {
    @media (max-width: 768px) {
      flex-direction: column;
      width: clamp(280px, 80%, 320px);
    }

    .imageWrapper {
      position: relative;
      flex: 1 1 auto;
      width: 36%;
      margin-right: var(--spacing-xs);
      border-radius: 0.25rem;
      overflow: hidden;

      @media (max-width: 768px) {
        width: 100%;
        height: 220px;
        margin-bottom: var(--spacing-xs);
        margin-right: 0;
        border-radius: 0.25rem 0.25rem 0 0;
      }
    }

    .info {
      flex: 1 1 auto;
      width: 64%;
      padding: var(--spacing-xs);
      padding-bottom: 1px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    span:global(.skeletonContainer) {
      position: relative;
      flex: 1;
      height: 100%;

      span:global(.react-loading-skeleton) {
        display: inline-block;
      }
    }
  }

  .link {
    display: grid;
    grid-template-columns: 4.5fr 7fr;
    grid-template-areas: "imageWrapper content";
    width: 100%;
    color: var(--dark-text);

    @media (max-width: 768px) {
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: auto;
      grid-template-areas:
        "imageWrapper"
        "content";
    }

    .imageWrapper {
      grid-area: imageWrapper;
      position: relative;
      align-self: start;
      width: 92%;
      // margin: auto;
      padding: var(--spacing-sm) 0 0 var(--spacing-xs);
      min-height: 200px;

      @media (max-width: 768px) {
        padding: 0;
        width: 100%;
      }

      .image {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        // border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
        border-radius: var(--border-radius-md);

        @media (max-width: 768px) {
          position: relative;
          object-fit: contain;
          width: auto;
          height: auto;
          max-width: 100%;
          border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
        }
      }

      .favoriteButton {
        top: 18px;
        right: 2px;
      }
    }

    .loadingAnimation {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: pink !important;
    }

    .price {
      width: 120px;
      font-weight: 600;
      text-align: right;

      @media (max-width: 768px) {
        display: flex;
        min-width: 150px;
        text-align: left;
        margin-top: var(--spacing-xxs);
      }

      .pricingFromLabel {
        display: block;
        font-size: var(--font-size-xs);

        @media (max-width: 768px) {
          margin-right: var(--spacing-xxs);
          font-size: calc(var(--font-size-md-sm) - 2px);
        }
      }

      .fromPrice {
        font-weight: 700;
        font-size: calc(var(--font-size-md-sm) - 2px);
      }
    }

    .rating {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      line-height: 1;
      margin: 0 var(--spacing-xxs) var(--spacing-xs) 0;

      .stars {
        display: flex;
        padding: 1.6px;
        margin-right: var(--spacing-xxs);
      }

      .totalReviews {
        margin-top: 2px;
        font-size: var(--font-size-sm);
        color: var(--dark-text);

        @media (max-width: 768px) {
          font-size: calc(var(--font-size-xs));
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      grid-area: content;
      padding: var(--spacing-xs);
      padding-left: 0;
      font-size: var(--font-size-xs);
      height: clamp(200px, 225px, 100%);

      @media (max-width: 768px) {
        padding: var(--spacing-xs);
        padding-top: 0;
        height: auto;
      }

      .topRow {
        position: relative;
        display: flex;

        @media (max-width: 768px) {
          flex-direction: column;
          text-align: left;
        }

        .titleContainer {
          flex: 1 1 auto;
          max-width: 72%;
          margin-top: 0;
          margin-bottom: var(--spacing-xs);
          padding-right: var(--spacing-md);

          @media (max-width: 768px) {
            padding-right: 0;
            max-width: 100%;
          }
        }

        .title {
          font-size: var(--font-size-md-sm);
          font-weight: 700;
          margin: 0;
          word-break: break-word;
        }
      }

      .location {
        flex: 1 1 auto;
        font-size: var(--font-size-sm);
        font-weight: 500;
        color: var(--mid-text);
        padding-bottom: var(--spacing-xs);
      }

      .description {
        position: relative;
        max-width: 100%;
        margin-top: var(--spacing-xxs);
      }

      .additionalInfo {
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        // margin-top: var(--spacing-xs);

        @media (max-width: 768px) {
          padding: 0 var(--spacing-xxs);
        }

        .flags {
          margin-right: var(--spacing-md);
        }
      }
    }
  }
}

.duration {
  padding-left: 2px;
  margin-right: var(--spacing-md);
}

.iconBox {
  display: flex;
  align-items: center;

  .icon {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: var(--spacing-xs);

    svg {
      position: relative;
      height: 100%;
    }
  }
}

.buttonWrapper {
  margin: var(--spacing-xs) 0 var(--spacing-sm) 0;
  padding: 0 var(--spacing-xs);
}

.container.mini {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-left: 0;
  margin-bottom: 0;
  border: 1px solid var(--mid-shadow);
  border-radius: var(--border-radius-md);
  position: relative;
  height: 120px;

  @media (min-width: 480px) {
    min-width: 340px;
  }

  .link {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "image content";
    color: var(--dark-text);

    @media (max-width: 480px) {
      grid-template-columns: 1.2fr 2fr;
    }

    .imageWrapper {
      grid-area: image;
      position: relative;
      align-self: start;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 120px;
      padding: var(--spacing-xs);

      .image {
        // position: absolute
        object-fit: cover;
        height: 100%;
        aspect-ratio: 1 /1;
        border-radius: var(--border-radius-md);
      }

      .loadingAnimation {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        :global(.wn-loading-animation) {
          &:before {
            // inset: 8px;
          }
        }
      }
    }
    .content {
      grid-area: content;
      display: flex;
      flex-direction: column;
      padding: var(--spacing-xs);
      font-size: var(--font-size-xs);

      .row1 {
        .titleContainer {
          .title {
            padding: 0;
            margin: 0;
            font-size: var(--font-size-sm);
            font-weight: 700;
          }
        }
      }
      .row2 {
        margin-top: var(--spacing-xxs);

        @media (max-width: 480px) {
          display: none;
        }
      }
      .row3 {
        display: flex;
        margin-top: auto;

        .price {
          margin-left: auto;

          .fromPrice {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.container.large {
  position: relative;
  border: 1px solid var(--dark-border);
  border-radius: var(--border-radius-md);

  &:hover {
    .imageWrapper {
      opacity: 0.92;
    }
  }

  .link {
    display: flex;
    flex-direction: column;
    .imageWrapper {
      position: relative;
      display: flex;
      justify-content: center;
      margin: auto;
      border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
      overflow: hidden;
      width: clamp(240px, 100%, 600px);
      transition: all 0.3s ease-in-out;

      .loadingAnimation {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-xs);

      .row1 {
        .titleContainer {
          .title {
            font-size: var(--font-size-md-sm);
            font-weight: 600;
            color: var(--dark-text);
            // text-decoration: underline;
            margin: var(--spacing-xs) 0;
          }

          .location {
            display: block;
            color: var(--mid-text);
            font-weight: 500;
          }
        }
      }
      .row2 {
        margin-bottom: var(--spacing-xxs);

        .totalReviews {
          color: var(--dark-text);
        }
      }
      .row3 {
        .durationFlags {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: var(--spacing-xs);
          color: var(--dark-text);

          .duration {
            display: flex;
            margin-right: var(--spacing-xs);
            min-width: 100px;
          }

          .flags {
            display: flex;
            flex-wrap: wrap;
            min-width: 160px;
          }
        }
        .price {
          color: var(--dark-text);
          .pricingFromLabel {
            font-weight: 400;
          }
          .fromPrice {
            font-weight: 700;
            font-size: calc(var(--font-size-md-sm) - 2px);
          }
        }
      }
    }
  }
}

.hideOnMobile {
  @media (max-width: 768px) {
    display: none !important;
  }
}
.hideOnDesktop {
  @media (min-width: 769px) {
    display: none !important;
  }
}

.title {
  word-break: break-word;
}
