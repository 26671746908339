.scrollIndicatorContainer {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  max-width: 40px;
  height: 100%;
  pointer-events: none;
  transition: all 0.3s ease-in-out;

  &.scrollIndicatorRight {
    right: 0;

    .scrollIndicator {
      background: linear-gradient(
        to left,
        var(--light-background),
        var(--light-background-transparent),
        transparent 95%
      );
    }
  }

  &.scrollIndicatorLeft {
    left: 0;

    .scrollIndicator {
      background: linear-gradient(
        to right,
        var(--light-background),
        var(--light-background-transparent),
        transparent 95%
      );
    }
  }

  .scrollIndicator {
    width: 100%;
    height: 100%;
    z-index: 50;
  }

  .scrollIndicator {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to left,
      var(--light-background) 40%,
      transparent 80%
    );
    z-index: 50;
  }
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
