.cardRow {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  scroll-margin-top: 180px;

  // width: 100%;
  // min-height: 300px;
  // @media (max-width: 767px) {
  //   // padding: 0 var(--spacing-xs);
  //   overflow-x: scroll;
  //   -ms-overflow-style: none; /* for Internet Explorer, Edge */
  //   scrollbar-width: none;
  //   &::-webkit-scrollbar {
  //     display: none; /* for Chrome, Safari, and Opera */
  //   }
  // }

  &.hasScrollIndicator {
    .cards {
      padding-right: 12%;
    }
  }

  .titleContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    // padding-left: 12px;
    @media (max-width: 768px) {
      display: block;
    }
    .titleText {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      // width: max-content;
    }
    .title {
      display: flex;
      flex-wrap: wrap;
      max-width: 100vw;
      margin-bottom: 0;
      font-size: var(--font-size-xxl);

      @media (max-width: 992px) {
        margin-bottom: var(--spacing-sm);
        font-size: var(--font-size-lg);
      }

      .firstWord {
        margin-right: 0.3em;
        padding-bottom: 0.3em;
      }
      .highlight {
        font-weight: 700;
      }
    }
  }

  .sideScrollButtons {
    position: absolute;
    top: 32%;
    width: 100%;
    pointer-events: none;

    // &.standalone {
    //   margin-bottom: 10px;
    //   margin-right: -16px;
    // }
  }

  .sectionLink {
    width: max-content !important;
    display: flex;
    align-items: flex-end;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    text-align: right;
    color: #0e1d28;
    margin: 0 0 0 2px;
    transition: all 0.3s ease-in-out;

    &.seeAll {
      margin: 0 0 var(--spacing-sm) 2px;

      span {
        font-weight: 600;
      }

      &.noTitle {
        @media (min-width: 768px) {
          margin-right: auto;
          min-height: 22px;
        }
      }
    }

    &:hover {
      color: var(--bs-link-hover-color);
    }

    .linkArrowWrapper {
      margin-left: 15px;
    }
  }

  .cards {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(30%, 220px), 1fr));
    grid-gap: var(--spacing-md);
    // justify-content: space-between;
    justify-content: start;
    overflow: hidden;
    max-width: 100%;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    @-moz-document url-prefix() {
      scrollbar-width: thin;
    }

    &.justify-center {
      justify-items: center;
    }
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    &.allSideScroll {
      grid-template-columns: unset;
      grid-auto-columns: 280px;
      grid-auto-flow: column; /* this will do the trick */
      overflow-x: auto;
      overflow-y: hidden;
    }
    &.mobileSideScroll {
      @media (max-width: 992px) {
        grid-template-columns: unset;
        grid-auto-columns: 300px;
        grid-auto-flow: column; /* this will do the trick */
        overflow-x: auto;
        overflow-y: hidden;
      }
    }
    &.desktopSideScroll {
      @media (min-width: 992px) {
        grid-template-columns: unset;
        grid-auto-columns: 300px;
        grid-auto-flow: column; /* this will do the trick */
        overflow-x: auto;
        overflow-y: hidden;
      }
    }
  }
}

.scrollIndicatorContainer {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10%;
  max-width: 40px;
  height: 100%;
  pointer-events: none;
  transition: all 0.3s ease-in-out;

  &.scrollIndicatorRight {
    right: 0;

    .scrollIndicator {
      background: linear-gradient(
        to left,
        var(--light-background),
        transparent 95%
      );
    }
  }

  &.scrollIndicatorLeft {
    left: 0;

    .scrollIndicator {
      background: linear-gradient(
        to right,
        var(--light-background),
        transparent 98%
      );
    }
  }

  .scrollIndicator {
    width: 100%;
    height: 100%;
    z-index: 50;
  }

  .scrollIndicator {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to left,
      var(--light-background) 40%,
      transparent 80%
    );
    z-index: 50;
  }
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
